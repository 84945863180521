import { getToken, jsonToURI } from "../../utils/general";
import { API_PERMISSION_PATHS, PERMISSION_ACTIONS } from "../../utils/status";
import { interceptorFunction } from "../interceptors";
const interceptor = interceptorFunction();

export const getMasterData = () => interceptor.get(`/master`);

export const getMasterCountryData = () => interceptor.get(`/master/countries`);

export const getProducts = (data: any) =>
  interceptor.get(`/itcu-products?data=${jsonToURI(data)}`, {
    headers: {
      authorization: `Bearer ${getToken()}`,
      "x-permission-value": JSON.stringify({
        [API_PERMISSION_PATHS.PRODUCT.LIST]: PERMISSION_ACTIONS.READ,
      }),
    },
  });

export const getDeviceMasterData = (type: string, UID: string, permissionPath: string = "") =>
  interceptor.get(`/component-master-data-list/${type}/${UID}`, {
    headers: {
      authorization: `Bearer ${getToken()}`,
      "x-permission-value": JSON.stringify({
        [permissionPath]: PERMISSION_ACTIONS.READ,
      }),
    },
  });

export const getSystemLogTypes = (data: any = {}) =>
  interceptor.get(`/system-log-type?data=${jsonToURI(data)}`, {
    headers: {
      authorization: `Bearer ${getToken()}`,
      "x-permission-value": JSON.stringify({
        [API_PERMISSION_PATHS.ANALYSIS.LIST]: PERMISSION_ACTIONS.READ,
      }),
    },
  });

export const getHelpPageEndpoint = (data: any = {}) =>
  interceptor.get(`/super/get-help-page-endpoint?data=${jsonToURI(data)}`, {
    headers: {
      authorization: `Bearer ${getToken()}`,
      "x-permission-value": JSON.stringify({
        [API_PERMISSION_PATHS.PROFILE.GET]: PERMISSION_ACTIONS.READ,
      }),
    },
  });
